/* Tsaol font */
@font-face {
  font-family: "tsaolRegular";
  src: url("../fonts/tsaol-regular.woff2") format("woff2"),
    url("../fonts/tsaol-regular.woff") format("woff");
}

@font-face {
  font-family: "tsaolBold";
  src: url("../fonts/tsaol-bold.woff2") format("woff2"),
    url("../fonts/tsaol-bold.woff") format("woff");
}

/* ------------------------------------- */
body {
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif;
  background: #eef0f2;
  overflow-x: hidden;
  height: 100%;
}

* {
  outline: none;
}

.rtl-dir {
  direction: rtl;
}
.ltr-dir {
  direction: ltr;
}

.transition {
  -webkit-transition: 250ms;
  transition: 250ms;
}

/* toast msg styles */
.errorToast {
  border-top: 4px solid rgba(248, 113, 113);
}
.successToast {
  border-top: 4px solid rgba(52, 211, 153);
}
.warnToast {
  border-top: 4px solid rgba(251, 191, 36);
}
.infoToast {
  border-top: 4px solid rgba(96, 165, 250);
}
/* toast msg styles */

/* ####### Quill editor ######## */
.ql-editor {
  direction: rtl !important;
  text-align: right !important;
  min-height: 200px !important;
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif !important;
  font-size: 16px !important;
}
.ql-editor strong,
.ql-editor b {
  font-family: "tsaolBold", "Segoe UI", Tahoma, Verdana, sans-serif !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0.2em !important;
  margin-right: -1.3em !important;
  text-align: right !important;
}
.ql-snow .ql-editor blockquote {
  border-left: none !important;
  padding-left: 0 !important;
  border-right: 4px solid #ccc !important;
  padding-right: 10px !important;
  font-style: italic;
  color: #767575;
}
.ql-editor ol,
.ql-editor ul {
  padding-right: 1.5em !important;
}

.ql-tooltip {
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif !important;
}
.ql-snow .ql-tooltip a.ql-action::after {
  content: "تعديل" !important;
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: "إزالة" !important;
}
.ql-snow .ql-tooltip::before {
  content: "زيارة الرابط" !important;
  margin-left: 8px !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "حفظ" !important;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "أدخل الرابط" !important;
}

/* ########### Modal ########## */
.modal {
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  z-index: 99;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

/* ###################################### */
/* ------------- Admin styles ---------- */
/* ###################################### */

/* ###### Modal ###### */
/* .modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    -webkit-transition: opacity 400ms;
    transition: opacity 400ms;
}

.modal {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
    -webkit-transition: 300ms;
            transition: 300ms;
}

.modal-wrapper .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: -1;
}

.modal-is-open{
    visibility: visible;
    opacity: 1;
}
.modal-is-open > .modal{
    -webkit-transform: translateY(0);
            transform: translateY(0)
}

.modal-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
}

@media (min-width: 576px){
    .modal{
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal.modal-lg{ max-width: 800px !important }
    .modal-centered { min-height: calc(100% - (1.75rem * 2)) }
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 1rem;
    outline: 0;
} */

/* ---- Table ---- */
table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table td {
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.dataTable-bottom,
.dataTable-top {
  @apply bg-gray-400;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
